import * as React from 'react'
import { graphql } from 'gatsby'

import ShowCard from '../components/ShowCard'

export const ShowCards = ({ slice }) => {
    return (
        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mb-6">
            {slice.items.map((item, i) => (
                <div key={i}>
                    <ShowCard show={item.show?.document} />
                </div>
            ))}
        </section>
    )
}

export const query = graphql`
    fragment ShowsPageDataBodyShowCards on PrismicShowsPageDataBodyShowCards {
        items {
            show {
                document {
                    ... on PrismicShow {
                        uid
                        data {
                            name {
                                text
                            }
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            quality: 100
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
