import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PlayIcon } from '@heroicons/react/solid'

export default function ShowCard({ show }) {
    if (!show) return null;
    
    const image = getImage(show.data?.image?.localFile)

    return (
        <div>
            <Link
                className="group relative flex"
                to={`/emissions-series/${show.uid}`}
                title={show.data?.name?.text}
            >
                <GatsbyImage image={image} alt={show.data?.name?.text} />
                <div className="absolute inset-0 flex flex-col items-center justify-center w-full bg-black opacity-0 group-hover:opacity-90 transition">
                    <PlayIcon className="mb-2 w-10 text-blue-700" />
                    <span className="hidden md:block text-white">{show.data?.name?.text}</span>
                </div>
            </Link>
        </div>
    )
}
